<template>
    <div class="inner-container">
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i>
                            {{ getBipTitle('filter.title') }}
                        </span>
                        <span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>{{ getFilterTitle('title') }}</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>
                        <!--------------- Тип объекта ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('object_type')">
                                <multiselect ref="bcObject"
                                             v-model="selObject"
                                             track-by="name"
                                             label="name"
                                             :placeholder="getFilterTitle('all')"
                                             :options="objectList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false"
                                             @input="getCriteriaValues">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Направление ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('direction')">
                                <multiselect ref="bcProject"
                                             v-model="selProject"
                                             track-by="name"
                                             label="name"
                                             :placeholder="getFilterTitle('all')"
                                             :options="directionList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false"
                                             @input="getCriteriaValues">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Место реализации ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('sale_place')">
                                <multiselect ref="bcPlace"
                                             v-model="selPlace"
                                             track-by="text"
                                             label="text"
                                             :placeholder="getFilterTitle('all')"
                                             :options="placeList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false"
                                             @input="getCriteriaValues">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Плановый период ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('plan_period')">
                                <multiselect v-show="yearsFilter"
                                             ref="bcPlanYear"
                                             v-model="planPeriod"
                                             track-by="name"
                                             label="name"
                                             :placeholder="getFilterTitle('all')"
                                             :options="yearsFilter"
                                             :searchable="false"
                                             :allow-empty="true"
                                             :show-labels="false"
                                             @input="getCriteriaValues">
                                </multiselect>
                            </b-form-group>
                        </div>
                    </div>
                    <br>
                    <b-button variant="secondary" @click="resetFilter">{{ getBipTitle('btn.reset') }}</b-button>
                </b-dropdown>
            </div>
            <div class="right-content">
                <div class="filter-actions">
                    <b-button variant="primary"
                              @click="duplicate"
                              :disabled="!(this.selObject && this.selProject && this.selPlace && this.planPeriod)">
                        <i class="icon icon-plus-circle"></i>{{ getBipTitle('btn.duplicate') }}
                    </b-button>
                    <b-button variant="success"
                              @click="save"
                              :disabled="!(this.selObject && this.selProject && this.selPlace && this.planPeriod)">
                        {{ getBipTitle('btn.save') }}
                    </b-button>
                </div>
            </div>
        </div>
        <!--        хлебные крошки-->
        <div class="filter-breadcrumb">
            <span class="item-breadcrumb" v-if="!openFilterWindow && selObject" @click="openFilterByRef('bcObject')">
                {{ selObject.name }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selProject" @click="openFilterByRef('bcProject')">
                {{ selProject.name }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selPlace" @click="openFilterByRef('bcPlace')">
                {{ selPlace.text }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && planPeriod" @click="openFilterByRef('bcPlanYear')">
                {{ planPeriod.name }}
            </span>
        </div>
        <div class="table-container">
            <b-table
                ref="selectableTable"
                :fields="tableFields"
                :items="showTable"
                :tbody-tr-class="rowClass"
                responsive="true"
                head-variant="light"
                bordered
                sticky-header
                no-border-collapse>
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>
                        <i class="icon icon-chevron-circle" v-if="!open"></i>
                    </b-button>
                </template>
                <template #cell(action)="data">
                    <b-button v-if="data.item.par_id === 0" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(name)="data">
                    <div>{{ data.item.name }}</div>
                </template>
                <template #cell(unit_item)="data">
                    <div>{{ data.item.unit }}</div>
                </template>
                <template #cell(value)="data">
                    <b-form-input v-if="data.item.par_id > 0 && data.item.data_type === 'number'"
                                  class="text-right"
                                  :disabled="!(data.item.source_type === 'W' && data.item.operator === 'CSI')"
                                  :value="$n(data.item.value)"
                                  @change="v => data.item.value = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'value', data.item.value, 1)">
                    </b-form-input>
                    <template v-if="data.item.par_id > 0 && data.item.data_type === 'boolean'">
                        <b-form-group class="medium no-label"
                                      :disabled="!(data.item.source_type === 'W' && data.item.operator === 'CSI')">
                            <b-form-checkbox switch
                                             v-model="data.item.value"
                                             @change="data.item.edit=true"
                            ></b-form-checkbox>
                        </b-form-group>
                    </template>
                    <template v-if="data.item.par_id > 0 && data.item.data_type === 'int'">
                        <b-form-group class="radio-horizontal">
                            <b-form-radio-group
                                v-model="data.item.value"
                                :disabled="!(data.item.source_type === 'W' && data.item.operator === 'CSI')"
                                :options="radioOptions"
                                name="radio-inline"
                                @change="data.item.edit=true">
                            </b-form-radio-group>
                        </b-form-group>
                    </template>
                </template>
                <template #cell(weight)="data">
                    <b-form-input v-if="data.item.par_id > 0"
                                  class="text-right"
                                  :value="$n(data.item.weight)"
                                  @change="v => data.item.weight = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'weight', data.item.weight, 2)">
                    </b-form-input>
                </template>
                <template #cell(max)="data">
                    <b-form-input v-if="data.item.par_id > 0"
                                  class="text-right"
                                  :value="$n(data.item.max)"
                                  @change="v => data.item.max = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'max', data.item.max, 1)">
                    </b-form-input>
                </template>
                <template #cell(min)="data">
                    <b-form-input v-if="data.item.par_id > 0"
                                  class="text-right"
                                  :value="$n(data.item.min)"
                                  @change="v => data.item.min = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'min', data.item.min, 1)">
                    </b-form-input>
                </template>
                <template #cell(more)="data">
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from "@/services/store";
import i18nService from "@/services/i18n";
import {
    doRowKey,
    findItem,
    getBipTitle,
    getFilterTitle,
    getNumber,
    getRowKey,
    getSmsTitle, getTFieldTitle, itemNameLocale,
    makeToast, setCurVariant, versionByRegion, years
} from './bip-types';

export default {
    name: 'bip-values',

    props: {
        host: undefined,

        objectList: {
            type: Array,
            default: () => []
        },
        directionList: {
            type: Array,
            default: () => []
        },
        dictUnit: {
            type: Array,
            default: () => []
        },
        bipCifList: {
            type: Array,
            default: () => []
        },
        bipLinkCriterias: {
            type: Array,
            default: () => []
        },
        bipLinkTypes: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            calcFlds: [
                'value',
                'weight',
                'max',
                'min'
            ],

            radioOptions: [
                { text: '-1', value: -1 },
                { text: '0', value: 0 },
                { text: '1', value: 1 }
            ],

            criteriaTypeList: [
                    {id: '1', par_id: 0, name_ru: 'Бюджетные', name_kk: 'Бюджеттік', name_en: 'Budget', child: []},
                    {id: '2', par_id: 0, name_ru: 'Влияние на ЦИ', name_kk: 'НИ-ге ықпалы', name_en: 'Impact on CI', child: []},
                    {id: '3', par_id: 0, name_ru: 'Потребность', name_kk: 'Қажеттілік', name_en: 'Need', child: []},
                    {id: '4', par_id: 0, name_ru: 'Дополнительные', name_kk: 'Қосымша', name_en: 'Additional', child: []}
            ],

            selObject: undefined,
            selProject: undefined,
            selPlace: undefined,
            planPeriod: undefined,

            valMap: new Map(),
            valTable: [],

            open: true,
            openFilterWindow: false,
            prevValues: 0
        };
    },

    async mounted() {
        await this.loadDatas();
    },

    beforeUpdate() {
        for (const row of this.valTable) {
            if (parseInt(row.par_id) === 0) {
                row._rowVariant = 'info';
            } else {
                this.$set(row, 'editable', true);
            }
        }
    },

    computed: {
        userUiid() {
            return store.state.user.sub;
        },

        yearsFilter() {
            return years();
        },

        placeList() {
            return [
                {value: 1, text: getBipTitle(this, 'dict.city')},
                {value: 2, text: getBipTitle(this, 'dict.village')}
            ]
        },

        tableFields() {
            return [
                {
                    key: 'action',
                    label: ''
                },
                {
                    key: 'name',
                    label: getBipTitle(this, 'criterias')
                },
                {
                    key: 'unit_item',
                    label: getTFieldTitle(this, 'unit')
                },
                {
                    key: 'value',
                    label: getTFieldTitle(this, 'value')
                },
                {
                    key: 'weight',
                    label: getTFieldTitle(this, 'weight')
                },
                {
                    key: 'max',
                    label: 'Max',
                    variant: 'warning'
                },
                {
                    key: 'min',
                    label: 'Min'
                },
                {
                    key: 'more',
                    label: ''
                }
            ]
        },

        // criteriaTypeList() {
        //     return [
        //         {id: '1', par_id: 0, name: getBipTitle(this, 'dict.budget'), child: []},
        //         {id: '2', par_id: 0, name: getBipTitle(this, 'dict.impact'), child: []},
        //         {id: '3', par_id: 0, name: getBipTitle(this, 'dict.need'), child: []},
        //         {id: '4', par_id: 0, name: getBipTitle(this, 'dict.additional'), child: []}
        //     ]
        // },

        showTable() {
            let result = [];
            if (this.selObject && this.selProject && this.selPlace && this.planPeriod) {
                result = this.valTable;

                if (this.selObject) {
                    result = result.filter(row => row.par_id === 0 || row.object_type === this.selObject.code);
                }

                if (this.selProject) {
                    result = result.filter(row => row.par_id === 0 || row.project_type === this.selProject.code);
                }

                if (this.selPlace) {
                    result = result.filter(row => row.par_id === 0 || row.place === this.selPlace.value);
                }

                if (this.planPeriod) {
                    result = result.filter(row => row.par_id === 0 || (row.begin_year <= this.planPeriod.year
                        && (row.end_date === null || (row.end_year >= this.planPeriod.year))));
                }

                if (result.length > 0) {
                    for (const row of result) {
                        if (row.par_id > 0) {
                            this.$set(row, 'year', this.planPeriod.year);
                        }
                    }
                }
            }

            return result;
        },

        lg() {
            return i18nService.locale;
        }
    },

    methods: {
        createTable(elem, parentId, table) { // создание таблицы на основе дерева
            const that = this;

            const item = Object.assign({}, elem);
            itemNameLocale(item);

            that.$set(item, 'par_id', parentId);
            that.$set(item, 'visible', true);

            that.$set(item, 'index', table.length);
            that.$set(table, table.length, item);

            if (item.par_id > 0) {
                that.calcFlds.forEach((field) => {
                    if (item.hasOwnProperty(field)) {
                        that.$set(item, field, parseFloat(item[field]));
                    } else {
                        that.$set(item, field, 0);
                    }
                });
            } else {
                that.$set(item, 'open', true);
                that.$set(item, 'hasChild', true);

                for (const ch of item.child) {
                    that.createTable(ch, item.id, table);
                }
            }

        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        async delete(item) {
            this.$set(item, 'table', 'bip_cif_list')
            const response = await fetch('/api-py/delete-item', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(item)
            });
            const result = await response.json();
            if (result.result === 'success') {
                await this.loadDict();
                makeToast(this, 'success', getSmsTitle(this, 'message'),
                 getSmsTitle(this, 'datas_saved'));
            }
        },

        async duplicate() {
            let answer = false;
            let sms = 'Вы действительно хотите скопировать данные с ' + (this.planPeriod.year - 1) + '-' + (this.planPeriod.year + 1)
                + ' периода. Если уже введены данные за ' + this.planPeriod.name + ', они будут удалены';
            if (i18nService.locale === 'kk') {
                sms = 'Сіз шынымен ' + (this.planPeriod.year - 1) + 'жылдан -' + (this.planPeriod.year + 1) + ' жылғы деректерді көшіргіңіз келе ме?'
                + 'Егер ' + this.planPeriod.name + ' жылғы деректер әлдеқашан енгізілген болса, олар жойылады';
            }
            if (i18nService.locale === 'en') {
                sms = 'Do you really want to copy the data from ' + (this.planPeriod.year - 1) + '-' + (this.planPeriod.year + 1)
                    + ' period. If data for ' + this.planPeriod.name + ' has already been entered, it will be deleted';
            }
            await this.$bvModal.msgBoxConfirm(
                sms,
                {
                    title: getSmsTitle(this, 'confirmation'),
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    cancelVariant: 'light',
                    okTitle: getBipTitle(this, 'btn.duplicate'),
                    cancelTitle: getBipTitle(this, 'btn.cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    modalClass: 'del-item-modal-script'
                }).then(value => {
                    answer = value;
                }).catch(() => {
                    makeToast(this, 'danger', getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'error_update'));
                });
            if (answer) {
                await this.getCriteriaValues(this.selObject.code,
                    this.selProject.code,
                    this.selPlace.value,
                    this.planPeriod.year - 1);

                if (this.prevValues === 0) {
                    makeToast(this, 'warning', getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'datas_prev_period_not_found'));
                } else {
                    for (const row of this.showTable) {
                        if (row.par_id > 0) {
                            this.calcFlds.forEach((field) => {
                                this.$set(row, field, 0);
                                this.$set(row, 'edit', true);
                            });
                            this.valMap.set(getRowKey(row, ['year', 'object_type', 'project_type', 'place', 'criteria']), row);

                            const value = this.valMap.get(doRowKey(
                                [this.planPeriod.year - 1, row.object_type, row.project_type, row.place, row.criteria]));
                            if (value) {
                                this.calcFlds.forEach((field) => {
                                    this.$set(row, field, value[field]);
                                });
                            }
                        }
                    }
                }
            }
        },

        async getCriteriaValues() {
            if (this.selObject && this.selProject && this.selPlace && this.planPeriod) {
                try {
                    const response = await fetch('/api-py/get-criteria-values-by-params/'
                        + this.selObject.code + '/' + this.selProject.code + '/' + this.selPlace.value + '/' + this.planPeriod.year)
                        .then((response) => response.json());

                    this.prevValues = await response.length;
                    for (const row of response) {
                        await this.valMap.set(getRowKey(row, ['year', 'object_type', 'project_type', 'place', 'criteria']), row);
                    }

                    setTimeout(() => {
                        for (const row of this.showTable) {
                            if (row.par_id > 0) {
                                const value = this.valMap.get(getRowKey(row, ['year', 'object_type', 'project_type', 'place', 'criteria']));
                                if (value) {
                                    this.calcFlds.forEach((field) => {
                                        this.$set(row, field, value[field]);
                                    });
                                }
                            }
                        }
                    }, 500);

                } catch (error) {
                    makeToast(this, 'danger',  getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'error_request') + ' /getCriteriaValues');
                }
            }
        },

        inputFixed(item, field, value, digit) {
            this.$set(item, field, getNumber(value, digit));

            this.$set(item, 'edit', true);
            this.valMap.set(getRowKey(item, ['year', 'object_type', 'project_type', 'place', 'criteria']), item);
        }, // форматирует введенное значение до digit цифр после запятой

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        async loadDatas() {
            try {

                for (const blc of this.bipLinkCriterias) {

                    const cif = this.bipCifList.filter(row => row.code === blc.criteria);
                    if (cif.length > 0) {
                        for (const [key, value] of Object.entries(cif[0])) {
                            if (!['id', 'begin_date', 'end_date'].includes(key)) {
                                this.$set(blc, key, value);
                            }
                        }
                        this.$set(blc, 'unit_item', findItem(blc.unit, 'national_symbol', this.dictUnit));
                    }

                    const blt = this.bipLinkTypes.filter(row => row.id === blc.link);
                    if (blt.length > 0) {
                        for (const [key, value] of Object.entries(blt[0])) {
                            if (key !== 'id') {
                                this.$set(blc, key, value);
                            }
                        }

                        this.$set(blc, 'begin_year', new Date(blt[0].begin_date).getFullYear());
                        if (blt[0].end_date !== null) {
                            this.$set(blc, 'end_year', new Date(blt[0].end_date).getFullYear());
                        } else {
                            this.$set(blc, 'end_year', null);
                        }
                    }

                    for (const gr of this.criteriaTypeList) {
                        if (gr.id === blc.criteria_type) {
                            this.$set(blc, 'par_id', gr.id);
                            gr.child.push(blc);
                        }
                    }
                }

                for (const gr of this.criteriaTypeList) {
                    this.createTable(gr, 0, this.valTable);
                }
            } finally {
                setTimeout(() => {
                    if (this.valTable.length <= 4) {
                        this.valTable = [];
                        this.loadDatas();
                    }
                }, 5000);

            }
        },

        message(sms) {
            this.$bvModal.msgBoxConfirm(
                sms, {
                    title: getSmsTitle(this, 'attention'),
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: getBipTitle(this, 'btn.correct'),
                    cancelTitle: getBipTitle(this, 'btn.cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    modalClass: 'del-item-modal-script'
                }).then(() => {
                    return;
                }).catch(() => {
                    makeToast(this, 'danger', getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'error_control'));
                });
        },

        openAll() {
            this.open = !this.open;

            for (const row of this.valTable) {
                if (row.par_id > 0) {
                    row.visible = this.open;
                } else {
                    this.openChilds(row, this.open);
                }
            }
        }, // открывает.закрывает все ветки

        openChilds(parent, bool) {
            parent.open = (bool !== undefined ? bool : !parent.open);

            for (const row of this.valTable) {
                if (parent.id === row.par_id) {
                    row.visible = parent.open;
                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        resetFilter() {
            this.selObject = null;
            this.selProject = null;
            this.selPlace = null;
            this.planPeriod = null;
        },

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        async save() {
            const values = [];
            for (const row of this.valMap.values()) {
                if (row.edit) {
                    this.$set(row, 'user_name', this.userUiid);
                    values.push(row);
                }
            }

            const response = await fetch('/api-py/save-bip-criteria-values', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(values)
            }).then((response) => response.json());

            if (response.result === 'success') {
                makeToast(this, 'success', getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'datas_saved'));
            }
        },

        getBipTitle(field) {
            return getBipTitle(this, field);
        },

        getFilterTitle(field) {
            return getFilterTitle(this, field);
        },

        getSmsTitle(field) {
            return getSmsTitle(this, field);
        }
    }
};
</script>

<style>
.is-hidden {
    display: none !important;
}
</style>
<style scoped>
.item-tool {
    margin-top: 5px;
}
</style>
